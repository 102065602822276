<template>
  <div>
    <b-row>
      <b-col>
        <quick-search
          :reset-filter="resetFilter"
          :search-data="searchData"
        />
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="detailSearch"
          class="mt-2"
          name="check-button"
          style="padding-top: 5px"
          switch
          inline
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="detailSearch">
      <b-col
        cols="12"
        md="4"
      >
        <brands :is-sales-brand="true" />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <statuses :default-value="'0'" />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <meets />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <users :custom-where="usersWhere" />
      </b-col>
      <b-col
        cols="12"
        md="8"
      >
        <dates />
      </b-col>
      <b-col
        class="text-center"
        cols="12"
      >
        <b-button
          variant="primary"
          @click="detailFilter"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
        <b-button
          class="ml-1"
          variant="warning"
          @click="resetFilter"
        >
          <FeatherIcon icon="XCircleIcon" />
          Sıfırla
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import Brands from '@/views/Interviews/components/Filters/Brands.vue'
import Statuses from '@/views/Interviews/components/Filters/Statuses.vue'
import Meets from '@/views/Interviews/components/Filters/Meets.vue'
import Users from '@/views/Interviews/components/Filters/Users.vue'
import Dates from '@/views/Interviews/components/Filters/Dates.vue'
import QuickSearch from '@/views/Interviews/components/Filters/QuickSearch.vue'

export default {
  name: 'SparePartsFilter',
  components: {
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    Brands,
    Statuses,
    Meets,
    Users,
    Dates,
    QuickSearch,
  },
  props: {
    detailFilter: {
      type: Function,
      required: true,
    },
    searchData: {
      type: Function,
      required: true,
    },
    resetFilter: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      usersWhere: {
        'com_user.status': 1,
      },
      keyword: null,
      detailSearch: false,
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['interviews/getInterviewFilters']
    },
    quickSearch() {
      return this.$store.getters['interviews/getQuickSearch']
    },
  },
}
</script>
